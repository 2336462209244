<template>
  <div class="write-sn-wrap">
    <div class="write-sn-header">
      <div style="padding: 10px;">
        <a @click="reloadApplication" class="help-docs-link" href="javascript:;">重置应用</a>
        <a class="help-docs-link" href="/docs/" target="_blank">帮助文档</a>
      </div>
    </div>
    <div class="write-sn-main">
      <div class="write-sn-title">物联网产品烧录工作台</div>
      <div class="is-link-rtu">
        <span style="color: green;cursor: pointer;" @click="handleshark" v-if="isLinkRTU">烧录制具已连接，点击测试握手协议</span>
        <span style="color: orangered;cursor: pointer;" @click="openSerail" v-else>未连接烧录制具，先点击选择串口</span>
      </div>
      <div class="write-sn-content">
        <div class="write-sn-input">
          <input ref="inputSNElement" @input="inputSN" type="text" v-model="SnValue" placeholder="请扫描入网授权码">
        </div>
        <!-- <div class="write-sn-options">
          进行设备测试：
          <label for="skipTestYes">是</label>
          <input id="skipTestYes" name="skipTest" type="radio" value="1" v-model="checkd">
          <label style="margin-left: 24px;" for="skipTestNO">否</label>
          <input id="skipTestNO" name="skipTest" type="radio" value="0" v-model="checkd">
        </div>
        <div class="write-sn-options">
          进行云端验证：
          <label for="skipTestYes">是</label>
          <input id="skipTestYes" name="serverCheck" type="radio" :value="1" v-model="store.isServerCheck">
          <label style="margin-left: 24px;" for="skipTestNO">否</label>
          <input id="skipTestNO" name="serverCheck" type="radio" :value="0" v-model="store.isServerCheck">
        </div> -->
        <div class="write-sn-log">
          <div class="write-log-title">烧录日志</div>
          <div class="write-log-list">
            <div v-for="(item, index) of logList" :key="index" class="write-log-item">
              <span>{{ item.time }}</span>
              <span>{{ item.content }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, computed } from 'vue';
import { useCommonStore } from '@/stores/common';
import { useCheckDeviceStore } from '@/stores/checkDevice';
import crc16modbus from '@/protocol/utils/crc16modbus';
import http from '@/http';
import * as SerialPort from '@/utils/SerialPort';
import * as _ from 'lodash';

const store = useCommonStore()
const checkDeviceStore = useCheckDeviceStore()
const isLinkRTU = computed(() => store.isLinkRTU)
const logList = computed(() => store.logList)

let SnValue = ref('')
let inputSNElement = ref(null)
let checkd = ref(0)

async function openSerail() {
  let result = await SerialPort.openSerialPort()
  setTimeout(() => {
    handleshark()
  }, 500)
  console.log('result', result)

}
function reloadApplication() {
  location.reload()
}

// 配合扫码枪，500毫秒只触发一次输入
let inputLocked = ref(true)
function debounce(fn) {
  _.debounce(async () => {
    if (inputLocked.value) {
      inputLocked.value = false
      await fn()
      setTimeout(() => inputLocked.value = true, 500)
    }
  }, 500)()
}

function handleshark() {
  // writeSerial([0xDC, 0x51, 0x00, 0x00, 0xAA, 0x0D, 0x0A, 0xFB, 0xAF])
  // console.log('下发握手协议：', `[0xDC, 0x51, 0x00, 0x00, 0xAA, 0x0D, 0x0A, 0xFB, 0xAF]`)
  // writeSerial([0xAA, 0x22, 0xB0, 0xFD, 0x00, 0xA2, 0xF1, 0xE7])
  // console.log('下发握手协议：', `[0xAA, 0x22, 0x02, 0xFD, 0x00, 0xA2, 0xF1, 0xE7]`)
  setTimeout(() => {
    inputSNElement.value.focus()
  }, 500)
}

checkDeviceStore.$subscribe(async (mutation, state) => {
  if (state.checkCode == '00') {
    writeSn()
  } else {
    // 测试异常重置SN号，方便二次输出
    SnValue.value = ''
  }
})

function inputSN() {
  debounce(async () => {
    store.resetLogList()
    let sn = SnValue.value.split('-')[0]
    store.$patch({snHex:sn})
    if (/^[0-9A-F]{8}$/.test(sn)) {
      console.log('SN合法')
      store.resetLogList()
      store.updateLogList(`接收到SN号：${sn}，等待下位机反馈...`)
      try {
        // 是否进行云端验证
        if (store.isServerCheck) {
          let result = await http.post('/api/serial-number/burn-in/verify', {
            sn: sn
          })
          console.log(result)
          store.upSnCheckSign(result.sign)
          store.updateLogList(`SN号：${sn} 校验通过`)
        }
        if (checkd.value == 1) {
          console.log('进行测试')
          writeSerial([0xDC, 0x51, 0x01, 0x00, 0x00, 0xFF, 0x0D, 0x0A, 0xB1, 0xB8])
          console.log('下发测试协议：', `[0xDC, 0x51, 0x01, 0x00, 0x00, 0xFF, 0x0D, 0x0A, 0xB1, 0xB8]`)
        } else {
          console.log('跳过测试')
          writeSn()
        }
      } catch (error) {
        SnValue.value = ''
        inputSNElement.value.focus()
        store.updateLogList(error)
      }
    } else {
      store.updateLogList(`SN号校验不通过，请重新输入`)
      SnValue.value = ''
      inputSNElement.value.focus()
      console.log('SN不合法')
    }
  })
  // console.log('输入SN') 10EA231B
  // console.log(SnValue.value)
}

async function writeSn() {
  let sn = SnValue.value.split('-')[0]
  let result = await http.post('/solar/solar-device-burn/burn-order', {
    txId:"1",
    snHex: sn,
    pnHex:sn
  })
  if(result.data === null) {
    store.updateLogList(`请先连接测试制具`)
    return false;
  }
  let hexMessage = hexMessageToFormattedArray(result.data.hexMessage)
  let sn_length = hexMessage.length.toString(16)
  sn_length = sn_length > 9 ? `0x${sn_length}`:`0x0${sn_length}`
  let crc16Value = generateCrc16([0xAA, 0x22, 0xB1, 0xFD, `${sn_length}`,...hexMessage, 0xA2])
  writeSerial([0xAA, 0x22, 0xB1, 0xFD, `${sn_length}`, ...hexMessage, 0xA2, Number(`${crc16Value[1]}`), Number(`${crc16Value[0]}`)])
  console.log('下发烧录协议：', [`0xAA`, `0x22`, `0xB1`, `0xFD`, `${sn_length}`,...hexMessage, `0xA2`, `${crc16Value[1]}`, `${crc16Value[0]}`])
  SnValue.value = ''
  inputSNElement.value.focus()
}

async function writeSerial(hexList) {
  // 例如：await SerialPort.writeSerailPort(new Uint8Array([0xAA, 0xBB]))
  try {
    await SerialPort.writeSerailPort(new Uint8Array(hexList))
    console.log('writeSerailPort')
  } catch {
    store.updateLogList(`请先连接测试制具`)
  }
}

// 生成CRC16校验值
function generateCrc16(hexList) {
  let stream = new Uint8Array(hexList)
  let crc16Str = crc16modbus(stream).toString(16).toUpperCase()
  if (crc16Str.length === 3) {
    crc16Str = `0${crc16Str}`;
  }
  return [`0x${crc16Str.substring(0, 2)}`, `0x${crc16Str.substring(2, 4)}`]
}
// 将接口返回的数据修改成0x格式
function hexMessageToFormattedArray(hexMessage) {  
  // 将hexMessage字符串分割为两位的数组  
  const hexPairs = hexMessage.match(/.{1,2}/g);
  // 遍历数组，将每个元素转换为形如'0xAB'的字符串，并存储在新的数组中  
  const formattedHexPairs = hexPairs.map(hexPair => {  
      // 使用前缀'0x'和字符串的toUpperCase()确保大写  
      return `0x${hexPair.toUpperCase()}`;  
  });
  // 返回包含格式化后字符串的数组  
  return formattedHexPairs;  
} 

onMounted(() => {
  // 监听设备连接
  navigator.serial.addEventListener("connect", () => {
    console.log('设备已连接')
    store.updateIsLinkRTU(true)
  });
  // 监听设备断开
  navigator.serial.addEventListener("disconnect", () => {
    console.log('设备已断开')
    SnValue.value = ''
    store.updateIsLinkRTU(false)
  });
  inputSNElement.value.focus()
  setTimeout(()=>{
    if(!isLinkRTU.value){
      store.updateLogList(`请先连接测试制具`)
    }
  },2000)
})
</script>

<style scoped>
.write-sn-header {
  text-align: right;
}

.write-sn-main {
  width: 800px;
  margin: 40px auto;
}

.write-sn-title {
  text-align: center;
  font-size: 36px;
}

.is-link-rtu {
  text-align: center;
  margin: 20px;
}

.write-sn-input {
  margin: 0 auto;
  width: 400px;
}

.write-sn-input input {
  width: 400px;
  height: 48px;
  text-align: center;
  font-size: 24px;
}

.write-sn-options {
  margin: 20px 200px;
}

.write-sn-log {
  margin: 20px 50px;
  border: 1px solid #eee;
  padding: 20px;
  height: 400px;
}

.write-sn-log .write-log-title {
  border-bottom: 1px solid #eee;
  padding-bottom: 15px;
}

.write-log-item {
  padding-top: 15px;
}

.write-log-item span+span {
  margin-left: 20px;
}
.help-docs-link {
  color: #666;
  margin-left: 20px;
}
.help-docs-link:hover {
  color: #5297f9;
}
</style>