import crc16modbus from "./utils/crc16modbus";
import { useCommonStore } from "@/stores/common";
import { useCheckDeviceStore } from "@/stores/checkDevice";
import checkCodeMsg from "@/protocol/checkErrCode";
import http from "@/http";
import * as SerialPort from '@/utils/SerialPort';

export default async function parser(stream) {
  if (stream.length === 0) return false;
  const commonStore = useCommonStore();
  const checkDeviceStore = useCheckDeviceStore();
  let bufferHex = buffer2Hex(stream);
  console.log("接收到报文：", bufferHex);
  console.log("=====开始解析=======");
  if (!checkCrc16(stream)) {
    // commonStore.updateLogList(`CRC16校验失败，请检查`);
    return "CRC16校验失败";
  }
  if (bufferHex[0] == "AA" && bufferHex[1] == "22") {
    // let snHex = "";
    console.log("包头校验通过");
    switch (bufferHex[2]) {
      // 握手检测
      case "02":
        if (bufferHex[3] == "FD" && bufferHex[4] == "00") {
          SerialPort.writeSerailPort(new Uint8Array([0xAA, 0x22, 0x02, 0xFD, 0x00, 0xA2, 0xF1, 0xE7]))
          console.log('下发握手协议：', `[0xAA, 0x22, 0x02, 0xFD, 0x00, 0xA2, 0xF1, 0xE7]`)
          console.log("握手成功");
          commonStore.updateLogList(`握手成功`);
        } else {
          console.log("");
          commonStore.updateLogList("握手失败");
        }
        break;
      // SN号扫描
      case "B0":
        if (bufferHex[3] == "FD" && bufferHex[4] == "00") {
          commonStore.updateLogList(`测试台请求扫码枪扫描二维码成功`);
          await sleep(2000)
          SerialPort.writeSerailPort(new Uint8Array([0xAA, 0x22, 0xB0, 0xFD, 0x00, 0xA2, 0xD6, 0x9F]))
          console.log('下发扫描二维码协议：', `[0xAA, 0x22, 0xB0, 0xFD, 0x00, 0xA2, 0xD6, 0x9F]`)
          await sleep(2000)
          commonStore.updateLogList('等待扫描入网授权码')
        } else {
          console.log("");
          commonStore.updateLogList("测试台请求扫码枪扫描二维码失败");
        }
        break;

      // 烧录信息反馈
      case "B1":
        console.log("烧录SN号：", commonStore.snHex);
        http.post('/solar/solar-device-burn/burn-confirm', {
          snHex: commonStore.snHex,
          hex: bufferHex.join("")
          // snHex:'21000952',
          // hex:"AA22B1FD16AB5113A000000000018090452CBE415C9A785FD1F153A2174C"
        })
          .then(async function(res) {
            if(res.isSuccess){
              // commonStore.updateLogList(`烧录成功`);
              let sn = commonStore.snHex.split('-')[0]
              let sn_1 = sn.substring(0, 2)
              let sn_2 = sn.substring(2, 4)
              let sn_3 = sn.substring(4, 6)
              let sn_4 = sn.substring(6, 8)
              let stream_B5 = new Uint8Array([0xAA, 0x22, 0xB5, 0xFD, 0x05, `0x${sn_1}`, `0x${sn_2}`, `0x${sn_3}`, `0x${sn_4}`,0x01, 0XA2])
              let crc16Str = crc16modbus(stream_B5).toString(16).toUpperCase()
              if (crc16Str.length === 3) {
                crc16Str = `0${crc16Str}`;
              }
              await sleep(5000)
              SerialPort.writeSerailPort(new Uint8Array([0xAA, 0x22, 0xB5, 0xFD, 0x05, `0x${sn_1}`, `0x${sn_2}`, `0x${sn_3}`, `0x${sn_4}`, 0x01, 0XA2, `0x${crc16Str.substring(2, 4)}`, `0x${crc16Str.substring(0, 2)}`]))
              console.log('下发B5协议：', [`0xAA`, `0x22`, `0xB5`, `0xFD`, `0x05`, `0x${sn_1}`, `0x${sn_2}`, `0x${sn_3}`, `0x${sn_4}`, `0x01`, `0XA2`, `0x${crc16Str.substring(2, 4)}`, `0x${crc16Str.substring(0, 2)}`])
              commonStore.updateLogList(
                `服务端反馈成功，请扫描下一个入网授权码`
              );
            }else{
              commonStore.updateLogList(`烧录失败`);
            }
            // setTimeout(() => {
            //   commonStore.resetLogList()
            // }, 5000)
            // if(res.isSuccess){


            // }else{
            //   commonStore.updateLogList(`校验失败`);
            // }
          })        
          .catch((error) => {
            console.log(error);
            commonStore.updateLogList(`服务端反馈失败，请检查～`);
          });

        // snHex = `${bufferHex[4]}${bufferHex[5]}${bufferHex[6]}${bufferHex[7]}`;
        // console.log("烧录SN号：", snHex);
        // if (commonStore.isServerCheck) {
        //   if (snHex == '00000000') {
        //     commonStore.updateLogList(
        //       `烧录SN号失败，请检查!!!`
        //     );
        //     return false
        //   }
        //   commonStore.updateLogList(
        //     `成功烧录SN号：${snHex}到设备，等待服务端反馈...`
        //   );
        //   http
        //     .post("/serial-number/burn-in/storage", {
        //       sn: snHex,
        //       sign: commonStore.snCheckSign,
        //     })
        //     .then((data) => {
        //       console.log(data);
        //       commonStore.updateLogList(
        //         `服务端反馈成功，请扫描下一个入网授权码`
        //       );
        //       // setTimeout(() => {
        //       //   commonStore.resetLogList()
        //       // }, 2000)
        //     })
        //     .catch((error) => {
        //       console.log(error);
        //       commonStore.updateLogList(`服务端反馈失败，请检查～`);
        //     });
        // } else {
        //   if (snHex == '00000000') {
        //     commonStore.updateLogList(
        //       `烧录SN号失败，请检查!!!`
        //     );
        //     return false
        //   }
        //   commonStore.updateLogList(
        //     `成功烧录SN号：${snHex}到设备，请扫描下一个入网授权码`
        //   );
        // }
        break;
        // 测试结果反馈
        case "01":
          console.log("测试结果：", checkCodeMsg[bufferHex[4]]);
          if (commonStore.isServerCheck) {
            http
              .post("/burn-in-check-log/save", {
                code: bufferHex[4],
                msg: checkCodeMsg[bufferHex[4]],
              })
              .then((data) => {
                console.log(data);
              })
              .catch((error) => {
                console.log(error);
              });
          }
  
          commonStore.updateLogList(`测试结果：${checkCodeMsg[bufferHex[4]] || '正常通过'}`);
          checkDeviceStore.$patch({
            checkCode: bufferHex[4],
          });
          break;
      default:
        console.log("未知功能码");
    }
  } else {
    console.log("包头校验失败");
  }
  // console.log(bufferHex[0]);
  // console.log(bufferHex[1]);
}

// CRC16校验
function checkCrc16(stream) {
  console.log("CRC16校验");
  let length = stream.length;
  let source = stream.slice(0, length - 2);
  let crc16_1 = stream.slice(length - 2, length - 1);
  let crc16_2 = stream.slice(length - 1, length);
  let crc16Str = crc16modbus(source).toString(16);
  if (crc16Str.length === 3) {
    crc16Str = `0${crc16Str}`;
  }
  if (
    Number(`0x${crc16Str.substring(0, 2)}`) == crc16_2 &&
    Number(`0x${crc16Str.substring(2, 4)}`) == crc16_1
  ) {
    console.log("CRC16校验成功");
    return true;
  } else {
    console.log("CRC16校验失败");
    return false;
  }
}
// toHex
function buffer2Hex(stream) {
  let arr = [];
  stream.forEach((item) => {
    let temp = item.toString(16).toUpperCase();
    let result = temp.length === 1 ? `0${temp}` : `${temp}`;
    arr.push(result);
  });
  return arr;
}

// 延时函数
function sleep(milliSec) {
  return new Promise(resovle => {
    setTimeout(resovle, milliSec)
  })
}
