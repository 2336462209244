// export default {
//   "00": "测试通过",
//   "01": "电池供电异常",
//   "02": "底板3.3V或12V检测异常",
//   "03": "程序烧录异常",
//   "04": "Inverter串口检测异常",
//   "05": "BMS串口检测异常",
//   "06": "PV输入异常07充电异常",
//   "08": "IO口检测异常",
//   "09": "ESP32模块检测异",
// };
export default {
  '01': '底板电流异常',
  '02': 'MPPT板3.3V异常',
  '03': 'MPPT板12V异常',
  '04': '程序烧录异常',
  '05': 'Inverter串口检测异常',
  '06': 'BMS串口检测异常',
  '07': '充电电池电压异常',
  '08': 'MPPT板PV输入电压异常',
  '09': 'MPPT板充电电流异常',
  '10': 'MIPPT板温度异常',
  '11': '使能10口异常',
  '12': '按键I0口异常',
  '13': 'LED1_R IO口异常（PV输入状态指示灯）',
  '14': 'LED1_G IO口异常（PV输入状态指示灯）',
  '15': 'LED2_R IO口异常（离并网状态指示灯）',
  '16': 'LED2_G I0口异常（离并网状态指示灯）',
  '17': 'LED3_R IO口异常（配网状态指示灯）',
  '18': 'LED3_G I0口异常（配网状态指示灯）',
  '19': 'LED4_R IO口异常（电量指示灯）',
  '20': 'LED4_G I0口异常（电量指示灯）',
  '21': 'LED5_G I0口异常（电量指示灯）',
  '22': 'LED6_G IO口昇常（电量指示灯）',
  '23': 'LED7_G IO口异常（电量指示灯）',
  '24': 'LED8_G IO口异常（电量指示灯）',
  '25': 'ESP32模块异常',
}
