import { defineStore } from "pinia";
import * as dayjs from "dayjs";

export const useCommonStore = defineStore("common", {
  state: () => ({
    token: localStorage.getItem("token"),
    snCheckSign: '',
    isServerCheck: 0,
    isLinkRTU: false,
    logList: [],
    snHex:''
  }),
  actions: {
    updateToken(value) {
      localStorage.setItem("token", value);
      this.token = value;
    },
    upSnCheckSign(value) {
      this.snCheckSign = value
    },
    updateIsLinkRTU(value) {
      console.log(value)
      this.isLinkRTU = value;
    },
    updateLogList(value) {
      let speechtext = new SpeechSynthesisUtterance()
      speechtext.rate = 2
      speechtext.text = value
      speechSynthesis.speak(speechtext)
      this.logList = [...this.logList, {
        time: dayjs().format("YYYY-MM-DD HH:mm:ss"),
        content: value,
      }];
    },
    resetLogList() {
      console.log("重置logList");
      this.logList = [
        {
          time: dayjs().format("YYYY-MM-DD HH:mm:ss"),
          content: "等待扫描入网授权码",
        },
      ];

    },
  },
});
