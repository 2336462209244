import { useCommonStore } from "@/stores/common";
import parser from '@/protocol/index'

// 当前打开的串口句柄
let currentPort = null;
let reader = null
let closedReadPromise = null
let keepReading = true
// 判断浏览器是否支持串口
export function isSupportSerailPort () {
  if ('serial' in navigator) {
    return true
  } else {
    return false;
  }}
// 获取之前访问过的串口，返回的是数组
export async function getHistorySerial() {
  const ports = await navigator.serial.getPorts();
  console.log(ports)
  return ports
}
// 打开浏览器串口,第一次需要用户手动选择完成授权
export async function openSerialPort() {
  try {
    // 返回选择的串口
    currentPort = await navigator.serial.requestPort();
    connectSerialPort(currentPort)
  } catch (error) {
    console.log(error)
  }
}
// 连接串口并监听串口数据
 export async function connectSerialPort(port) {
  try {
    currentPort = port
    await port.open({
      dataBits: 8, // 数据位
      stopBits: 1, // 停止位
      parity: 'none', // 奇偶校验
      baudRate: 115200, // 波特率
    })
    const { usbProductId, usbVendorId } = port.getInfo();
    const commonStore = useCommonStore();
    commonStore.updateIsLinkRTU(true)
    console.log('usbProductId', usbProductId)
    console.log('usbVendorId', usbVendorId)
    console.log(currentPort)
    closedReadPromise = readSerail(currentPort)
  } catch (error) {
    console.log(error)
  }
}
// 读取串口数据
async function readSerail(port) {
  let serialData = []
  while(port.readable && keepReading) {
    reader = port.readable
    .getReader()
    let innerLoop = true
    while (innerLoop) {
      const { value, done } = await reader.read();
      if (done) {
        // 允许稍后关闭串口
        console.log('允许稍后关闭串口')
        reader.releaseLock();
        console.log('跳出内层')
        break;
      }
      serialData.push(...value)
      setTimeout(() => {
        parser(serialData)
        serialData = []
      }, 200)
      // if (value) {
      //   // 只接收报尾标识符为十六进制\r\n'的数据，否则会放在缓冲区
      //   console.log('value', value);
      //   parser(value)
      // }
    }
  }
}
// 写入串口数据：data是Unit8Array类型
export async function writeSerailPort(data) {
  const writer = currentPort.writable.getWriter();
  await writer.write(data)
  writer.releaseLock()
}
// 关闭串口
export async function closeSerialPort() {
  if (currentPort) {
    console.log(currentPort)
    // 关闭串口的前提是串口的readable和writable被解锁
    const readerLocked = currentPort.readable.locked;
    console.log('readerLocked', readerLocked)
    if(readerLocked) {
      keepReading = false
      console.log('取消读取锁定')
      reader.cancel()
      await closedReadPromise
      console.log(readerLocked)
    }
    const writerLocked = currentPort.writable.locked;
    console.log('writerLocked', writerLocked)
    if(writerLocked) {
      currentPort.writable.getWriter().releaseLock()
    }
    currentPort.close()
  }
}