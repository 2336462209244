<template>
  <div class="login-content">
    <div class="login-title">入网授权码烧录工作台</div>
    <div>
      <input v-model="account" type="text" placeholder="请输入账号">
    </div>
    <div>
      <input v-model="password" type="password" placeholder="请输入密码">
    </div>
    <div>
      <button @click="login" type="button">登陆</button>
    </div>
  </div>
</template>

<script setup>
import http from '@/http'
import { ref } from 'vue'
import { useCommonStore } from '@/stores/common'
import { useRouter } from 'vue-router'

const commonStore = useCommonStore()
const router = useRouter()
console.log(router)

const account = ref('burnadmin')
const password = ref('123456')

async function login() {
  let {token} = await http.post('/oauth/no-token/login', {
    account: account.value,
    password: password.value,
    grantType: 'USERNAME_PASSWORD'
  })
  commonStore.updateToken(token.token)
  router.push({
    name: 'home'
  })
}
</script>

<style>
.login-content {margin: 300px auto 0;width: 300px;border: 1px solid #eee;padding: 20px;text-align: center;}
.login-content > div {margin-bottom: 20px;}
.login-content input { width: 200px; height: 30px;line-height: 30px;padding-left: 20px;}
.login-content button { width: 220px;height: 36px;line-height: 30px;cursor: pointer;}
</style>