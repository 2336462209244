import axios from "axios";
// import { useCommonStore } from "@/stores/common"
import router from '@/router'

console.log(process.env)
const instance = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
  timeout: 10000,
  headers:{
    // 'Authorization':'Bearer test'
    tenant:'0000',
    timezone:'Asia/Shanghai'
  },

});

instance.interceptors.request.use(
  (config) => {
    // const commonStore = useCommonStore()
    // 登陆接口不需要带上token，否则token过期会导致服务端误判401
    if (config.url !== '/oauth/no-token/login') {
      // config.headers["Authorization"] = `Bearer ${commonStore.token}`;
      config.headers["Authorization"] = 'Bearer test';
    }
    return config;
  },
  (error) => {
    console.log(error);
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (response) => {
    const res = response.data;
    if (res.code !== 0) {
      console.log("接口信息报错1", res.msg);
      return Promise.reject(res.msg);
    } else {
      return res;
    }
  },
  (error) => {
    console.log("接口信息报错2" + error);
    if (error.response.data.code === 401) {
      console.log('token过期，返回登录页面')
      router.replace({
        name: 'login'
      })
    }
    return Promise.reject(error);
  }
);

/**
 * 封装get方法
 * @param url
 * @param data
 * @returns {Promise}
 */
function get(url, params = {}) {
  return new Promise((resolve, reject) => {
    instance
      .get(url, {
        params: params,
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

/**
 * 封装post请求
 * @param url
 * @param data
 * @returns {Promise}
 */
function post(url, data = {}) {
  return new Promise((resolve, reject) => {
    instance.post(url, data).then(
      (response) => {
        resolve(response);
      },
      (err) => {
        reject(err);
      }
    );
  });
}

export default {
  get,
  post,
};
