import { createRouter, createWebHistory } from "vue-router";
import Home from "@/views/home/homeIndex";
import Login from "@/views/login/loginIndex";
const routes = [
  {
    name: 'login',
    path: '/login',
    component: Login
  },
  {
    name: 'home',
    path: "/",
    component: Home,
  },
];

export default createRouter({
  history: createWebHistory(),
  routes,
});
